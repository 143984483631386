import React from 'react';

function GameSetupForm({ onSubmit }) {
  return (
    <div className="game-setup-container">
      <form className="game-setup-form" onSubmit={onSubmit}>
        <h2>CloudJo Game Setup</h2>
        <div className="form-group">
          <label htmlFor="roomName">Room Name:</label>
          <input
            type="text"
            id="roomName"
            name="roomName"
            required
            style={{ textAlign: 'center' }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="playerCount">Number of Players:</label>
          <select id="playerCount" name="playerCount" required>
            <option value="">Select players</option>
            <option value="2">2 players</option>
            <option value="3">3 players</option>
            <option value="4">4 players</option>
            <option value="5">5 players</option>
            <option value="6">6 players</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="playerName">Your Name:</label>
          <input
            type="text"
            id="playerName"
            name="playerName"
            required
            style={{ textAlign: 'center' }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Choose a password</label>
          <input type="password" id="password" name="password" required />
        </div>
        <button type="submit">Create Game</button>
      </form>
    </div>
  );
}

export default GameSetupForm;