import { useState, useEffect } from 'react';

export const useChatWebSocket = (roomKey) => {
    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (!roomKey) return;
        
        let wsUrl;
        if (window.location.hostname === 'localhost') {
            wsUrl = 'ws://localhost:8100/api/ws/chat';
        } else {
            const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            wsUrl = `${protocol}//${window.location.host}/api/ws/chat`;
        }
        const ws = new WebSocket(wsUrl);
    
        ws.onopen = () => {
            console.log('Connected to chat room');
            ws.send(JSON.stringify({ room_key: roomKey }));
        };
    
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'chat_message') {
                setMessages(prev => {
                    const isDuplicate = prev.some(m => 
                        m.sender === message.data.sender && 
                        m.content === message.data.content && 
                        m.timestamp === message.data.timestamp
                    );
                    return isDuplicate ? prev : [...prev, message.data];
                });
            }
        };
    
        ws.onerror = (error) => console.error('Chat WebSocket error:', error);
        setSocket(ws);
    
        return () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, [roomKey]);

    const sendMessage = (messageObj) => {
        if (socket) {
            socket.send(JSON.stringify(messageObj));
        }
    };

    return { messages, sendMessage };
};