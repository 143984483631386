import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './GameRoom.css';
import InfoBar from './components/InfoBar';
import ChatContainer from './components/ChatContainer';
import { useChatWebSocket } from './hooks/useChatWebSocket';
import { useGameStateWebSocket } from './hooks/useGameStateWebSocket';
import GameContainer from './components/GameContainer';

function GameRoom() {
    const location = useLocation();
    const navigate = useNavigate();
    const roomData = location.state?.roomData;
    const playerName = location.state?.playerName;
    const roomKey = roomData?.room_key || location.pathname.split('/').pop();
    const [newMessage, setNewMessage] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);

    const { gameState } = useGameStateWebSocket(roomKey);
    const { messages, sendMessage } = useChatWebSocket(roomKey);

    useEffect(() => {
        if (!roomData) {
            navigate('/', {
                state: { 
                    showJoinRoom: true,
                    prefillRoomKey: roomKey
                }
            });
        }
    }, [roomData, navigate, roomKey]);

    useEffect(() => {
        if (playerName) {
            const isPlayerTurn = gameState?.game_state?.current_turn === playerName;
            document.title = `CloudJo - ${playerName}${isPlayerTurn ? ' 🎲 ' : ''}`;
        }
        
        return () => {
            document.title = 'CloudJo';
        };
    }, [playerName, gameState?.game_state?.current_turn]);


    
    const handleCopyRoomKey = async () => {
        if (gameState?.room_key) {
            try {
                await navigator.clipboard.writeText(gameState.room_key);
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000);
            } catch (err) {
                console.error('Failed to copy room key:', err);
            }
        }
    };

    const handleSendMessage = () => {
        if (!newMessage.trim()) return;
        
        sendMessage({
            sender: playerName,
            content: newMessage,
            timestamp: new Date().toISOString()
        });
        setNewMessage('');
    };
    const isGameOwner = gameState?.players.find(player => player.name === playerName)?.isOwner;
    const isWaiting = gameState?.status === 'waiting';

    const handleStartGame = async () => {
        try {
            const response = await fetch('/api/start-game', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    room_key: roomKey,
                    player_name: playerName,
                }),
            });
            
            if (!response.ok) {
                const error = await response.json();
                console.error('Failed to start game:', error);
            }
        } catch (error) {
            console.error('Error starting game:', error);
        }
    };

    const handleCardClick = async (cardId, isOwnCard, isExchange = false) => {
        try {
            if(isExchange) {
                await fetch('/api/game/exchange-cards', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        room_key: roomKey,
                        player_name: playerName,
                        card_id: cardId
                    }),
                });
            } else {
            await fetch('/api/game/flip-card', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    room_key: roomKey,
                    player_name: playerName,
                    card_id: cardId,
                    is_own_card: isOwnCard
                })
            });
        }
        } catch (error) {
            console.error('Error flipping card:', error);
        }
    };

    const handlePileClick = () => {
        handleCardClick(null, false);
    };

    return (
        <div style={styles.gameLayout}>
            <div style={styles.roomContainer}>
                <header style={styles.roomHeader}>
                    <div className="room-title">
                        <h1 style={styles.roomTitle}>{gameState?.room_name}</h1>
                    </div>
                    <div style={styles.roomInfo}>
                        <InfoBar 
                            playerName={playerName}
                            gameState={gameState}
                            handleCopyRoomKey={handleCopyRoomKey}
                            copySuccess={copySuccess}
                        />
                    </div>
                </header>
                <div style={styles.mainContent}>
                    <GameContainer 
                        gameState={gameState}
                        playerName={playerName}
                        isGameOwner={isGameOwner}
                        isWaiting={isWaiting}
                        handleStartGame={handleStartGame}
                        handleCardClick={handleCardClick}
                        handlePileClick={handlePileClick}
                    />
                    
                    <div className="chat-container">
                        <ChatContainer 
                            gameState={gameState}
                            messages={messages}
                            playerName={playerName}
                            newMessage={newMessage}
                            setNewMessage={setNewMessage}
                            handleSendMessage={handleSendMessage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    gameLayout: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: '1fr',
        height: '100vh',
        gap: '1rem',
        padding: '1rem'
    },
    roomContainer: {
        display: 'contents'
    },
    roomHeader: {
        gridRow: 1,
        gridColumn: '1 / -1',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        backgroundColor: '#f5f5f5',
        padding: '1rem',
        borderBottom: '1px solid #e0e0e0'
    },
    roomTitle: {
        margin: 0,
        padding: 0,
        color: '#333',
        fontSize: '1.5rem'
    },
    roomInfo: {
        width: '100%'
    },
    mainContent: {
        gridRow: 2,
        display: 'grid',
        gridTemplateColumns: '80fr 20fr',
        gap: '1rem'
    }
};

export default GameRoom;