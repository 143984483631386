import React from 'react';

export const PlayerCard = ({ card, isSelectable, onClick, isCurrentPlayer }) => (
    <div 
        style={{
            ...styles.cardItem,
            ...(card.isFaceDown ? styles.faceDown : getCardStyle(card.value)),
            ...(isCurrentPlayer && isSelectable ? styles.selectable : {}),
            ...(onClick ? { cursor: 'pointer' } : {}),
        }}
        onClick={onClick}
    >
        {card.isFaceDown ? 'X' : card.value}
    </div>
);

export const FaceUpPileCard = ({ value, isSelected, onClick, isEnabled }) => (
    <div 
        style={{
            ...styles.cardItem,
            ...getCardStyle(value),
            ...(isSelected ? styles.selected : {}),
            ...(isEnabled ? { cursor: 'pointer' } : {}),
        }}
        onClick={onClick}
    >
        {value}
    </div>
);

export const DrawPileCard = ({ pileCount, onClick, isEnabled }) => (
    <div 
        style={{
            ...styles.cardItem,
            ...styles.faceDown,
            ...(isEnabled ? { cursor: 'pointer' } : {}),
        }}
        onClick={onClick}
    >
        {pileCount}
    </div>
);

const getCardStyle = (value) => {
    if (value <= -1) return styles.blueCard;
    if (value === 0) return styles.lightBlueCard;
    if (value <= 4) return styles.greenCard;
    if (value <= 8) return styles.yellowCard;
    return styles.redCard;
};

const styles = {
    cardItem: {
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        zIndex: 1,
    },
    faceDown: {
        background: 'linear-gradient(45deg, #8ed1fc, #007bff, #28a745, #ffc107, #dc3545)',
        color: '#ffffff', // white text for better visibility
    },
    blueCard: {
        backgroundColor: '#007bff', // blue
        color: '#ffffff',
    },
    lightBlueCard: {
        backgroundColor: '#8ed1fc', // light blue
        color: '#000000',
    },
    greenCard: {
        backgroundColor: '#28a745', // green
        color: '#ffffff',
    },
    yellowCard: {
        backgroundColor: '#ffc107', // yellow
        color: '#000000',
    },
    redCard: {
        backgroundColor: '#dc3545', // red
        color: '#ffffff',
    },
    selectable: {
        border: '2px solid yellow',
        cursor: 'pointer',
    },
    selected: {
        border: '2px solid rgba(255, 0, 0, 0.3)',
    },
    drawPile: {
        padding: '40px',
        width: '100px',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.5em',
        backgroundColor: '#ddd',
    },
    faceUpCard: {
        padding: '40px',
        width: '100px',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.5em',
        backgroundColor: 'white',
    }
};