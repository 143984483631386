import React from 'react';

function JoinRoomForm({ onSubmit, prefillRoomKey = '' }) {
  return (
    <>
    <h2>CloudJo Join Room</h2>
    <form onSubmit={onSubmit} className="join-room-form">
      <div className="form-group">
        <label htmlFor="playerName">Your Name:</label>
        <input
          type="text"
          id="playerName"
          name="playerName"
          required
          minLength="2"
          maxLength="20"
        />
      </div>

      <div className="form-group">
        <label htmlFor="roomKey">Room Key:</label>
        <input
          type="text"
          id="roomKey"
          name="roomKey"
          defaultValue={prefillRoomKey}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="password">Room Password:</label>
        <input
          type="password"
          id="password"
          name="password"
        />
      </div>

      <button type="submit">Join Room</button>
      </form>
    </>
  );
}

export default JoinRoomForm;