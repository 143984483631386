import React from 'react';

function InfoBar({ playerName, gameState, handleCopyRoomKey, copySuccess }) {
    return (
        <div style={styles.infoBar}>
            <div style={styles.infoItem}>
                <span style={styles.infoLabel}>Player:</span>
                <span style={styles.infoValue}>{playerName}</span>
            </div>
            <div style={styles.infoItem}>
                <span style={styles.infoLabel}>Key:</span>
                <span 
                    style={{...styles.infoValue, ...styles.clickable}}
                    onClick={handleCopyRoomKey}
                >
                    {gameState?.room_key}
                </span>
                {copySuccess && <span style={styles.copyMessage}>Copied!</span>}
            </div>
            <div style={styles.infoItem}>
                <span style={styles.infoLabel}>Owner:</span>
                <span style={styles.infoValue}>
                    {gameState?.players?.find(player => player.isOwner)?.name || 'Loading...'}
                </span>
            </div>
            <div style={styles.infoItem}>
                <span style={styles.infoLabel}>Status:</span>
                <span 
                    style={{
                        ...styles.infoValue,
                        ...styles.statusBadge,
                        backgroundColor: gameState?.status === 'started' ? '#4CAF50' : '#FFC107',
                        color: gameState?.status === 'started' ? 'white' : 'black'
                    }}
                >
                    {gameState?.status || 'Waiting...'}
                </span>
            </div>
        </div>
    );
}

const styles = {
    infoBar: {
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        padding: '0.5rem',
        backgroundColor: '#f8f9fa',
        borderBottom: '1px solid #e0e0e0',
        marginBottom: '2rem',
        flexWrap: 'wrap'
    },
    infoItem: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.9rem',
        gap: '0.25rem'
    },
    infoLabel: {
        fontWeight: 600,
        color: '#666'
    },
    infoValue: {
        color: '#333'
    },
    clickable: {
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            opacity: 0.8
        }
    },
    copyMessage: {
        marginLeft: '8px',
        color: '#4CAF50',
        fontSize: '0.9em'
    },
    statusBadge: {
        padding: '4px 12px',
        borderRadius: '12px'
    }
};

export default InfoBar;
