import React from 'react';

function ChatContainer({ gameState, messages, playerName, newMessage, setNewMessage, handleSendMessage }) {
    return (
        <div style={styles.container}>
            <div style={styles.playersList}>
                <h3 style={styles.playersListH3}>
                    Players Online
                </h3>
                <ul style={styles.playersListUl}>
                    {gameState?.players?.map(player => (
                        <li 
                            key={player.name} 
                            style={{
                                ...styles.playersListLi,
                                ...(player.isOwner ? styles.playersListLiOwner : {})
                            }}
                        >
                            {player.name} {player.isOwner ? '(Owner)' : ''}
                        </li>
                    ))}
                </ul>
            </div>
            
            <div style={styles.chatBox}>
                <div style={styles.messages}>
                    {messages.map((msg, index) => (
                        <div key={index} style={styles.message}>
                            <span style={styles.sender}>{msg.sender === playerName ? 'You' : msg.sender}:</span>
                            <span>{msg.content}</span>
                        </div>
                    ))}
                </div>
                
                <div style={styles.messageInput}>
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        placeholder="Type a message..."
                        style={styles.input}
                    />
                    <button 
                        onClick={handleSendMessage}
                        style={styles.button}
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    playersList: {
        padding: '15px',
        background: '#f5f5f5',
        borderBottom: '1px solid #ccc',
    },
    playersListH3: {
        margin: '0 0 10px 0',
    },
    playersListUl: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    playersListLi: {
        padding: '5px 0',
    },
    playersListLiOwner: {
        fontWeight: 'bold',
    },
    chatBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    messages: {
        flex: 1,
        padding: '15px',
        overflowY: 'auto',
    },
    message: {
        marginBottom: '8px',
    },
    sender: {
        fontWeight: 'bold',
        marginRight: '8px',
    },
    messageInput: {
        display: 'flex',
        padding: '10px',
        borderTop: '1px solid #ccc',
        background: '#f5f5f5',
    },
    input: {
        flex: 1,
        padding: '8px',
        marginRight: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    button: {
        padding: '8px 16px',
        background: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            background: '#0056b3',
        },
    },
};

export default ChatContainer;
