import { useState, useEffect } from 'react';

export const useGameStateWebSocket = (roomKey) => {
    const [socket, setSocket] = useState(null);
    const [gameState, setGameState] = useState(null);

    useEffect(() => {
        if (!roomKey) return;

        let wsUrl;
        if (window.location.hostname === 'localhost') {
            wsUrl = 'ws://localhost:8100/api/ws/game';
        } else {
            const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            wsUrl = `${protocol}//${window.location.host}/api/ws/game`;
        }
        const ws = new WebSocket(wsUrl);
    
        ws.onopen = () => {
            console.log('Connected to game room');
            ws.send(JSON.stringify({ room_key: roomKey }));
        };
    
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);
            console.log('Game state message:', message);
            if (message.type === 'game_update') {
                setGameState(message.data);
            }
        };
    
        ws.onerror = (error) => console.error('WebSocket error:', error);
        ws.onclose = () => console.log('Disconnected from game room');
    
        setSocket(ws);
    
        return () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, [roomKey]);

    return { socket, gameState };
};