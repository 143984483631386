import React from 'react';
import { PlayerCard, FaceUpPileCard, DrawPileCard } from './Cards';

function GameContainer({ gameState, playerName, isGameOwner, isWaiting, handleStartGame, handleCardClick, handlePileClick }) {
    const [selectedFaceUpCard, setSelectedFaceUpCard] = React.useState(false);

    const handleFaceUpPileClick = () => {
        // Only allow clicking if it's player's turn and in main phase
        if (gameState.game_state?.current_turn !== playerName || gameState.game_state?.turn_phase === 'initial') {
            return;
        }
        setSelectedFaceUpCard(true);
    };

    const handlePlayerCardClick = (cardId) => {
        if (selectedFaceUpCard) {
            // Exchange the cards
            handleCardClick(cardId, false, true);
            setSelectedFaceUpCard(false);
        } else {
            // Normal card flip
            handleCardClick(cardId, true);
        }
    };

    const getCardClickHandler = (player, cardId) => {
        if (player !== playerName) return undefined;
        
        // Allow clicking only if:
        // 1. It's initial phase (for flipping 2 cards)
        // 2. Player has selected face-up card for exchange
        if (gameState.game_state?.turn_phase === 'initial' || selectedFaceUpCard) {
            return () => handlePlayerCardClick(cardId);
        }
        
        return undefined;
    };

    return (
        <div style={styles.gameContainer}>
            {isGameOwner && isWaiting && (
                <button 
                    style={styles.startGameButton}
                    onClick={handleStartGame}
                >
                    Start Game
                </button>
            )}
            {gameState?.status === 'started' && (
                <div className="game-started-message">
                    <div style={styles.gameBoard}>
                        <div style={styles.topPlayers}>
                            {Object.entries(gameState.game_state?.player_cards || {})
                                .sort(([player]) => player === playerName ? -1 : 1)
                                .map(([player, cards], index) => {
                                    if (index % 2 === 0) return null; // Skip even-indexed players (they go to bottom)
                                    
                                    return (
                                        <div key={player} className={`opponent-cards ${player === gameState.game_state?.current_turn ? 'active-player' : ''}`}>
                                            <h3>
                                                {player}
                                                {player === gameState.game_state?.current_turn && " (Current Turn)"}
                                            </h3>
                                            <div className="cards-grid" style={styles.cardsGrid}>
                                                {cards.map((card) => (
                                                    <PlayerCard 
                                                        key={card.id}
                                                        card={card}
                                                        isSelectable={selectedFaceUpCard}
                                                        onClick={getCardClickHandler(player, card.id)}
                                                        isCurrentPlayer={player === playerName}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                        <div style={styles.middleSection}>
                            <DrawPileCard 
                                pileCount={gameState.game_state?.pile?.length || 0}
                                onClick={() => handlePileClick()}
                                isEnabled={gameState.game_state?.current_turn === playerName && gameState.game_state?.turn_phase !== 'initial'}
                            />
                            <FaceUpPileCard 
                                value={gameState.game_state?.face_up_pile?.[gameState.game_state?.face_up_pile?.length - 1]?.value}
                                isSelected={selectedFaceUpCard}
                                onClick={handleFaceUpPileClick}
                                isEnabled={gameState.game_state?.current_turn === playerName && gameState.game_state?.turn_phase !== 'initial'}
                            />
                        </div>

                        <div style={styles.bottomPlayers}>
                            {Object.entries(gameState.game_state?.player_cards || {})
                                .sort(([player]) => player === playerName ? -1 : 1)
                                .map(([player, cards], index) => {
                                    if (index % 2 === 1) return null; // Skip odd-indexed players (they go to top)
                                    
                                    return (
                                        <div key={player} className={`player-cards ${player === playerName ? 'current-player' : ''} ${
                                            player === gameState.game_state?.current_turn ? 'active-player' : 'inactive-player'
                                        }`}>
                                            <h3>
                                                {player === playerName ? 'Your cards' : player}
                                                {player === gameState.game_state?.current_turn && " (Current Turn)"}
                                            </h3>
                                            <div style={styles.cardsGrid} className={`cards-grid ${
                                                player === playerName && player !== gameState.game_state?.current_turn ? 'disabled-cards' : ''
                                            }`}>
                                                {cards.map((card) => (
                                                    <PlayerCard 
                                                        key={card.id}
                                                        card={card}
                                                        isSelectable={selectedFaceUpCard}
                                                        onClick={getCardClickHandler(player, card.id)}
                                                        isCurrentPlayer={player === playerName}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    cardsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(4, 1fr)',
        gap: '10px',
        maxWidth: '400px',
        margin: '0 auto',
    },
    gameContainer: {
        // backgroundColor: '#98b9a8',
        borderRadius: '8px',
        padding: '20px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'inset 0 0 20px rgba(0, 0, 0, 0.3)',
        height: '100%',
    },
    gameBoard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '80vh',
        padding: '20px',
        position: 'relative',
    },
    topPlayers: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        flexWrap: 'wrap',
    },
    bottomPlayers: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        flexWrap: 'wrap',
    },
    middleSection: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        margin: '20px 0',
    },
    startGameButton: {
        padding: '12px 24px',
        fontSize: '1.2em',
        backgroundColor: '#e6b800',
        color: '#000',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        width: '30%',
        '&:hover': {
            backgroundColor: '#ffcc00',
        },
    },
    playerCards: {
        width: '300px',
        textAlign: 'center',
    },
    activePlayer: {
        position: 'relative',
        padding: '10px',
        border: '3px solid #080221',
        borderRadius: '12px',
        opacity: 1,
    },
    inactivePlayer: {
        opacity: 0.8,
    },
};

export default GameContainer;