import './App.css';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GameSetupForm from './components/GameSetupForm';
import JoinRoomForm from './components/JoinRoomForm';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [showCreateRoom, setShowCreateRoom] = useState(null);

  useEffect(() => {
    if (location.state?.showJoinRoom) {
      setShowCreateRoom(false);
    }
  }, [location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const roomName = formData.get('roomName');
    const numPlayers = parseInt(formData.get('playerCount'));
    const password = formData.get('password');
    const playerName = formData.get('playerName')

    try {
      const response = await fetch('/api/create-room', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          room_name: roomName,
          num_players: numPlayers,
          password: password,
          player_name: playerName
        })
      });
      
      const data = await response.json();
      if (data.error) {
        setErrorMessage(data.error);
      } else {
        setErrorMessage('');
        navigate(`/room/${data.room_key}`, {
          state: { 
            roomData: data,
            playerName: playerName
          }
        });
      }
    } catch (error) {
      setErrorMessage('Failed to create room');
    }
  };

  const handleJoinRoom = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const roomKey = formData.get('roomKey');
    const password = formData.get('password');
    const playerName = formData.get('playerName');

    try {
      const response = await fetch('/api/join-room', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          room_key: roomKey,
          password: password,
          player_name: playerName
        })
      });
      
      const data = await response.json();
      if (data.error) {
        setErrorMessage(data.error);
      } else {
        setErrorMessage('');
        navigate(`/room/${roomKey}`, {
          state: { 
            roomData: data,
            playerName: playerName
          }
        });
      }
    } catch (error) {
      console.error('Error joining room:', error);
      setErrorMessage('Failed to join room');
    }
  };

  const dismissError = () => {
    setErrorMessage('');
  };

  return (
    <div className="App centered-container">
      
      
      {errorMessage && <div className="error-message">{errorMessage} <button onClick={dismissError} className="dismiss-button">×</button></div>}
      
      {showCreateRoom === null && (
        <>
          <h1>Welcome to CloudJo</h1>
          <div>
            <div className="form-toggle button-container">
          <button 
            onClick={() => setShowCreateRoom(true)}
            className={showCreateRoom === true ? 'active' : ''}
          >
            Create Room
          </button>
          <button 
            onClick={() => setShowCreateRoom(false)}
            className={showCreateRoom === false ? 'active' : ''}
          >
            Join Room
          </button>
        </div>
          </div>
        </>
      )}

      {showCreateRoom !== null && (
        showCreateRoom ? (
          <GameSetupForm onSubmit={handleSubmit} />
        ) : (
          <JoinRoomForm 
            onSubmit={handleJoinRoom}
            prefillRoomKey={location.state?.prefillRoomKey}
          />
        )
      )}
    </div>
  );
}

export default App;
